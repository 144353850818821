import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { LandingPage } from "./pages/LandingPage";
import { Footer } from "./components/Footer";
import { About } from "./pages/About";
import { Projects } from "./pages/Projects";
import Contact from "./components/Contact/Contact";
import { NotFound } from "./pages/NotFound";
import { ProjectInfo } from "./components/ProjectInfo";
import { ProjectPage } from "./pages/ProjectPage";
import smoothscroll from "smoothscroll-polyfill";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    if (typeof window !== undefined) {
      smoothscroll.polyfill();
      window.__forceSmoothScrollPolyfill__ = true;
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Navbar />
        <Contact />
        <Routes>
          <Route path={"/"} element={<LandingPage />} />
          <Route path={"/about"} element={<About />} />
          <Route path={"/projects"} element={<Projects />} />
          <Route path={"/project"} element={<ProjectPage />}>
            <Route path={":id"} element={<ProjectInfo />} />
          </Route>
          <Route path={"*"} element={<NotFound />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
