import React from "react";
import { projects } from "../../data/projectsList";
import { Link } from "react-router-dom";
import { BsArrowRightShort } from "@react-icons/all-files/bs/BsArrowRightShort";
import { BsArrowLeftShort } from "@react-icons/all-files/bs/BsArrowLeftShort";
import { motion } from "framer-motion";

export const ProjectNav = ({ prev, next }) => {
  // Project ID's start at 1

  return (
    <div className={"projectNav"}>
      <motion.div className={"noselect"} whileHover={{ scale: 1.1 }}>
        {prev !== 0 && (
          <Link to={"/project/" + prev} as="a" aria-label={"Previous project"}>
            <BsArrowLeftShort />
            <h2>Previous project</h2>
          </Link>
        )}
      </motion.div>
      <motion.div
        whileHover={{ scale: 1.1 }}
        className={"backToProjects noselect"}
      >
        <Link
          to={"/projects"}
          state={{ scrollFalse: true }}
          as="a"
          aria-label={"Back to all project"}
        >
          <h2>Back to all projects</h2>
        </Link>
      </motion.div>

      <motion.div className={"noselect"} whileHover={{ scale: 1.1 }}>
        {next <= projects.length && (
          <Link to={"/project/" + next} as="a" aria-label={"Next project"}>
            <h2>Next project</h2>
            <BsArrowRightShort />
          </Link>
        )}
      </motion.div>
    </div>
  );
};
