import React, { useEffect, useState } from "react";
import "./style.css";
import { useParams } from "react-router-dom";
import { getProject } from "../../data/projectsList";
import { useNavigate } from "react-router-dom";
import { Asset } from "./Asset";
import { ContentView } from "./ContentView";
import { motion } from "framer-motion";
import { ProjectNav } from "./ProjectNav";
import { BsArrowRightShort } from "@react-icons/all-files/bs/BsArrowRightShort";
import { BsArrowLeftShort } from "@react-icons/all-files/bs/BsArrowLeftShort";

export const ProjectInfo = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [project, setProject] = useState(id);
  const [activeMedia, setMedia] = useState(0);

  useEffect(() => {
    const nextProject = getProject(parseInt(id));

    // If Project not found, redirect to 404
    if (!nextProject) {
      navigate("/404");
    }
    setProject(nextProject);
    setMedia(0);
  }, [id]);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  console.log(getProject(parseInt(id)));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [project]);

  return (
    <div className={"projectDetail"}>
      <div className={"projectTitle"}>{project && <h1>{project.name}</h1>}</div>

      <ProjectNav prev={project.id - 1} next={project.id + 1} />
      {project && (
        <ContentView
          content={project.media}
          folder={project.directory}
          activeIndex={activeMedia}
        />
      )}

      {project.media && project.media.length > 1 && (
        <div className={"mediaNav"}>
          {activeMedia > 0 && (
            <div
              className={"mediaNavLeft"}
              onClick={() => setMedia((current) => current - 1)}
            >
              <BsArrowLeftShort />
            </div>
          )}

          {activeMedia < project.media.length - 1 && (
            <div
              className={"mediaNavRight"}
              onClick={() => setMedia((current) => current + 1)}
            >
              <BsArrowRightShort />
            </div>
          )}
        </div>
      )}

      {project.media && project.media.length > 1 && (
        <motion.div
          className={"contentPicker"}
          variants={container}
          initial="hidden"
          animate="show"
        >
          {project.media.map((asset, index) => (
            <Asset
              key={asset.file}
              media={asset}
              folder={project.directory}
              active={activeMedia === index}
              updateActive={() => setMedia(index)}
            />
          ))}
        </motion.div>
      )}
    </div>
  );
};
