import React, { useState } from "react";
import "./Contact.css";
import turningWheel from "./../../assets/spin.png";
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail";
import { Link } from "react-router-dom";

function Contact() {
  const rotateRef = React.useRef(null);

  React.useEffect(() => {
    document.addEventListener("scroll", () => {
      if (rotateRef) {
        rotateRef.current.style.transform = `rotate(${window.scrollY / 10}deg)`;
        rotateRef.current.style.webkitTransform = `-webkit-rotate(${
          window.scrollY / 10
        }deg)`;
      }
    });
  });

  return (
    <>
      <div className={"scrollButtonWrapper noselect"}>
        <div ref={rotateRef}>
          <img src={turningWheel} alt={"contact me"} />
        </div>
        <Link to={"/#contact"} as="a" aria-label={"Contact Link"}>
          <button
            className={"contactMeButton"}
            as="a"
            aria-label={"openContact"}
          >
            <AiOutlineMail color={"white"} />
          </button>
        </Link>
      </div>
    </>
  );
}

export default Contact;
