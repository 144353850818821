import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useForm, ValidationError } from "@formspree/react";
import "./style.css";
import axios from "axios";

const ContactForm = ({ runAnimation }) => {
  const [state, handleSubmit] = useForm("xrgngbze");
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    if (state.succeeded) {
      setMessageSent(true);
      runAnimation();
    }
  }, [state.succeeded]);

  return (
    <div className={"formWrapper"}>
      {messageSent && (
        <div className="emailSent">
          <p>
            Thank you for contacting us! We will get back to you as soon as
            possible. Feel free to call us on the phone number if you require an
            urgent answer!
          </p>
          <div className={"contactAgain"} onClick={() => setMessageSent(false)}>
            <p>Send another message</p>
          </div>
        </div>
      )}

      <Formik
        initialValues={{ email: "", name: "", message: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.name) {
            errors.name = "Required";
          } else if (values.name.length > 64) {
            errors.name = "Name too long, 64 characters allowed";
          }

          if (!values.message) {
            errors.message = "Required";
          }

          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className={"formInputWrapper"}>
              <label htmlFor={"name"}>Name</label>
              <Field type="name" name="name" id={"name"} />
              <div className={"errorDivContainer"}>
                <ErrorMessage name="name" component="div" />
                <ValidationError
                  prefix="Name"
                  field="name"
                  errors={state.errors}
                />
              </div>
            </div>
            <div className={"formInputWrapper"}>
              <label htmlFor={"email"}>Email</label>
              <Field type="email" name="email" id={"email"} />
              <div className={"errorDivContainer"}>
                <ErrorMessage name="email" component="div" />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
            </div>
            <div className={"formInputWrapper"}>
              <label htmlFor={"message"}>Message</label>
              <Field
                type="textarea"
                name="message"
                component={"textarea"}
                id={"message"}
              />
              <div className={"errorDivContainer"}>
                <ErrorMessage name="message" component="div" />
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </div>
            </div>
            <button
              type="submit"
              disabled={state.submitting}
              name={"sendEmail"}
            >
              <p>Send</p>
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
