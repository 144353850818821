import React from "react";
import "./style.css";
import { Outlet } from "react-router-dom";

export const ProjectPage = () => {
  return (
    <div className={"projectPage"}>
      <Outlet />
    </div>
  );
};
