// Get project by ID
export const getProject = (id) => {
  return projects.find((project) => project.id === id);
};

// Link to S3 bucket accessed via CloudFront
export const root = "https://d185iu4tbca78w.cloudfront.net/";

const formula = {
  id: 1,
  name: "Formula One",
  directory: "formula1/",
  video: "video1.mp4",
  poster: "ff1.png",
  logo: "logo.png",
  media: [{ type: "video", file: "video1.mp4", thumb: "ff1.jpg" }],
};

const mkae = {
  id: 2,
  name: "M-KAE",
  directory: "mkae/",
  video: "video1.mp4",
  poster: "ff1.jpg",
  logo: "logo.png",
  media: [
    { type: "video", file: "video1.mp4", thumb: "ff2.jpg" },
    { type: "video", file: "video2.mp4", thumb: "ff3.jpg" },
    { type: "video", file: "video3.mp4", thumb: "ff1.jpg" },
    { type: "photo", file: "photo1.jpg" },
    { type: "photo", file: "photo2.jpg" },
    { type: "photo", file: "photo3.jpg" },
    { type: "photo", file: "photo4.jpg" },
    { type: "photo", file: "photo5.jpg" },
    { type: "photo", file: "photo6.jpg" },
    { type: "photo", file: "photo7.jpg" },
    { type: "photo", file: "photo8.jpg" },
    { type: "photo", file: "photo9.jpg" },
    { type: "photo", file: "photo10.jpg" },
    { type: "photo", file: "photo11.jpg" },
    { type: "photo", file: "photo12.jpg" },
    { type: "photo", file: "photo13.jpg" },
    { type: "photo", file: "photo14.jpg" },
    { type: "photo", file: "photo15.jpg" },
  ],
};

const deanlewis = {
  id: 3,
  name: "Dean Lewis",
  directory: "deanlewis/",
  video: "video1.mp4",
  poster: "ff1.jpg",
  logo: "logo.png",
  media: [
    { type: "video", file: "video1.mp4", thumb: "ff1.jpg" },
    { type: "photo", file: "photo1.jpg" },
    { type: "photo", file: "photo2.jpg" },
    { type: "photo", file: "photo3.jpg" },
    { type: "photo", file: "photo4.jpg" },
    { type: "photo", file: "photo5.jpg" },
    { type: "photo", file: "photo6.jpg" },
    { type: "photo", file: "photo7.jpg" },
    { type: "photo", file: "photo8.jpg" },
    { type: "photo", file: "photo9.jpg" },
    { type: "photo", file: "photo10.jpg" },
    { type: "photo", file: "photo11.jpg" },
    { type: "photo", file: "photo12.jpg" },
    { type: "photo", file: "photo13.jpg" },
    { type: "photo", file: "photo14.jpg" },
  ],
};

const ironman = {
  id: 4,
  name: "Ironman",
  directory: "ironman/",
  video: "video1.mp4",
  poster: "ff1.jpg",
  logo: "logo.png",
  media: [
    { type: "video", file: "video1.mp4", thumb: "ff1.jpg" },
    { type: "video", file: "video2.mp4", thumb: "ff2.jpg" },
    { type: "photo", file: "photo1.jpg" },
    { type: "photo", file: "photo2.jpg" },
    { type: "photo", file: "photo3.jpg" },
    { type: "photo", file: "photo4.jpg" },
    { type: "photo", file: "photo5.jpg" },
    { type: "photo", file: "photo6.jpg" },
    { type: "photo", file: "photo7.jpg" },
    { type: "photo", file: "photo8.jpg" },
    { type: "photo", file: "photo9.jpg" },
    { type: "photo", file: "photo10.jpg" },
    { type: "photo", file: "photo11.jpg" },
  ],
};

const samsoe = {
  id: 5,
  name: "Samsøe Samsøe",
  directory: "samsoe/",
  video: "video1.mp4",
  poster: "ff1.jpg",
  logo: "logo.png",
  media: [{ type: "video", file: "video1.mp4", thumb: "ff1.jpg" }],
};

const technogym = {
  id: 6,
  name: "Technogym",
  directory: "technogym/",
  video: "video1.mp4",
  poster: "ff1.jpg",
  logo: "logo.png",
  media: [
    { type: "video", file: "video1.mp4", thumb: "ff1.jpg" },
    { type: "video", file: "video2.mp4", thumb: "ff2.jpg" },
  ],
};

const musikilejet = {
  id: 7,
  name: "Musik i Lejet",
  directory: "musikilejet/",
  video: "video1.mp4",
  poster: "ff1.jpg",
  logo: "logo.png",
  media: [{ type: "video", file: "video1.mp4", thumb: "ff1.jpg" }],
};

const roskilde = {
  id: 8,
  name: "Roskilde Festival",
  directory: "roskilde/",
  video: "video1.mp4",
  poster: "ff1.jpg",
  logo: "logo.png",
  media: [{ type: "video", file: "video1.mp4", thumb: "ff1.jpg" }],
};

const bmw = {
  id: 9,
  name: "BMW - Jan Nygaard",
  directory: "bmw/",
  video: "video1.mp4",
  poster: "ff1.jpg",
  logo: "logo.png",
  media: [{ type: "video", file: "video1.mp4", thumb: "ff1.jpg" }],
};

const hudsonvalley = {
  id: 10,
  name: "Hudson Valley",
  directory: "hudsonvalley/",
  video: "video1.mp4",
  poster: "ff1.jpg",
  logo: "logo.png",
  media: [{ type: "video", file: "video1.mp4", thumb: "ff1.jpg" }],
};

const reddi = {
  id: 11,
  name: "Reddi",
  directory: "reddi/",
  video: "video1.mp4",
  poster: "ff1.jpg",
  logo: "logo.png",
  media: [
    { type: "video", file: "video1.mp4", thumb: "ff1.jpg" },
    { type: "video", file: "video2.mp4", thumb: "ff2.jpg" },
  ],
};

const hetmoment = {
  id: 12,
  name: "Het moment",
  directory: "hetmoment/",
  video: "video1.mp4",
  poster: "ff1.png",
  logo: "logo.png",
  media: [{ type: "video", file: "video1.mp4", thumb: "ff1.jpg" }],
};

const arcticice = {
  id: 13,
  name: "Arctic Ice",
  directory: "arcticice/",
  video: "video1.mp4",
  poster: "ff1.png",
  logo: "logo.png",
  media: [{ type: "video", file: "video1.mp4", thumb: "ff1.jpg" }],
};

const cphfw = {
  id: 14,
  name: "CPHFW 2023",
  directory: "cphfw/",
  video: "video1.mp4",
  poster: "ff1.png",
  logo: "logo.png",
  media: [{ type: "video", file: "video1.mp4", thumb: "ff1.jpg" }],
};

const luggagehero = {
  id: 15,
  name: "Luggage Hero",
  directory: "luggagehero/",
  video: "video1.mp4",
  poster: "ff1.png",
  logo: "logo.png",
  media: [{ type: "video", file: "video1.mp4", thumb: "ff1.jpg" }],
};

const atelte = {
  id: 16,
  name: "A-TELTE",
  directory: "atelte/",
  video: "video1.mp4",
  poster: "ff1.png",
  logo: "logo.png",
  media: [{ type: "video", file: "video1.mp4", thumb: "ff1.jpg" }],
};

const zeekr = {
  id: 17,
  name: "Zeekr",
  directory: "zeekr/",
  video: "video1.mp4",
  poster: "ff1.png",
  logo: "logo.png",
  media: [{ type: "video", file: "video1.mp4", thumb: "ff1.jpg" }],
};

// Final lists to export
export const projects = [
  formula,
  zeekr,
  luggagehero,
  arcticice,
  ironman,
  samsoe,
  technogym,
  musikilejet,
  roskilde,
  bmw,
  hudsonvalley,
  reddi,
  hetmoment,
  cphfw,
  atelte,
  mkae,
  deanlewis,
];
