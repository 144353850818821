import React from "react";
import { root } from "../../data/projectsList";
import { motion } from "framer-motion";
import { BsFillPlayFill } from "@react-icons/all-files/bs/BsFillPlayFill";

export const Asset = ({ media, folder, active, updateActive }) => {
  const url = root + folder;

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };

  const activeAnim = {
    border: active
      ? "2px solid rgba(37, 166, 214, 1)"
      : "2px solid rgba(255, 255, 255, 0)",
    transition: {
      duration: 0.25,
    },
  };

  return (
    <motion.div variants={item}>
      <motion.div
        className={"contentPickElement"}
        onClick={updateActive}
        animate={activeAnim}
      >
        {media.type === "photo" && (
          <img
            src={url + "small-" + media.file}
            alt={url + "small-" + media.file}
          />
        )}
        {media.type === "video" && (
          <>
            <img src={url + "small-" + media.thumb} alt={media.thumb} />
            <div className={"videoIndicator"}>
              <BsFillPlayFill />
            </div>
          </>
        )}
      </motion.div>
    </motion.div>
  );
};
