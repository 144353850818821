export const team = [
    {
        name: "Rik",
        title: "All-round creative",
        email: "rik@clixproductions.com",
        image: 'team/rik.png'
    },
    {
        name: "Xavier",
        title: "All-round creative",
        email: "xavier@clixproductions.com",
        image: 'team/sipho.png'
    },
    {
        name: "Dominik",
        title: "All-round creative",
        email: "dominik@clixproductions.com",
        image: 'team/dominik.png'
    },
    {
        name: "Thomas",
        title: "Business development",
        email: "thomas@clixproductions.com",
        image: 'team/thomas.png'
    },
]