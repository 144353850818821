import React from "react";
import { motion } from "framer-motion";

const ServiceBox = ({ icon, desc, title }) => {
  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };

  return (
    <motion.div className={"serviceBox"} variants={item}>
      <div className={"serviceIcon"}>{icon}</div>
      <h3>{title}</h3>
      <p>{desc}</p>
    </motion.div>
  );
};

export default ServiceBox;
