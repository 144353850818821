import { BsCameraVideoFill } from "@react-icons/all-files/bs/BsCameraVideoFill";
import { BsGraphUp } from "@react-icons/all-files/bs/BsGraphUp";
import { BsPencil } from "@react-icons/all-files/bs/BsPencil";
import { RiPaintBrushFill } from "@react-icons/all-files/ri/RiPaintBrushFill";
import { AiFillCamera } from "@react-icons/all-files/ai/AiFillCamera";
import { IoIosFingerPrint } from "@react-icons/all-files/io/IoIosFingerPrint";

export const services = [
  {
    title: "Video/Film",
    desc: "Video enhances visibility, engagement, and credibility through effective messaging and storytelling. From promotional to commercial, campaigns to product videos, we got you. We do editing, color grading, animations, 3D, sound design, drone and much, much more.",
    icon: <BsCameraVideoFill />,
  },
  {
    title: "Graphic/Design",
    desc: "Graphic design plays a crucial role in creating a brand's visual identity/ greatly impacts the success of a campaign. We have a strong understanding of design principles and the ability to create visually compelling designs that effectively communicate ideas and messages.",
    icon: <RiPaintBrushFill />,
  },
  {
    title: "Photo/Stills",
    desc: "Some of the most compelling content is captured in stills. We create visually striking and high-quality images that tell your story.",
    icon: <AiFillCamera />,
  },
  {
    title: "Branding",
    desc: "We will help you stand out in your industry, by creating a professional and impactful brand image that will attract and retain customers, allowing you to successfully grow your business.",
    icon: <IoIosFingerPrint />,
  },
  {
    title: "Copywriting",
    desc: "We will elevate your brand’s messaging and craft persuasive, compelling and effective language that will help you achieve your business goals.",
    icon: <BsPencil />,
  },
  {
    title: "SoMe management",
    desc: "We will help you to create, schedule, analyze and engage with your audience on social media. We walk you through the best strategical practices to maximize your online engagement.",
    icon: <BsGraphUp />,
  },
];
