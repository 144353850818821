import VideoJS from "../VideoJS/VideoJS";
import React from "react";
import { root } from "../../data/projectsList";

export const ContentView = ({ content, folder, activeIndex }) => {
  if (content) {
    const videoJsOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      // loadingSpinner: false,
      fluid: true,
      poster: root + folder + "small-" + content[activeIndex].thumb,
      sources: [
        {
          src: root + folder + content[activeIndex].file,
          type: "video/mp4",
        },
      ],
    };

    return (
      <div className={"contentContainer"}>
        <div className={"contentWrapper"}>
          {content[activeIndex].type === "video" && (
            <VideoJS options={videoJsOptions} />
          )}
          {content[activeIndex].type === "photo" && (
            <img
              src={root + folder + content[activeIndex].file}
              alt={"Project " + activeIndex}
            />
          )}
        </div>
      </div>
    );
  }
};
