import React, { useEffect } from "react";
import "./style.css";
import {
  useIndicatorUpdate,
  useIndicatorValue,
} from "../../utils/slideIndicator";
import { motion } from "framer-motion";

export const Indicator = () => {
  const slide = useIndicatorValue();
  const setSlide = useIndicatorUpdate();

  return (
    <div className={"indicatorContainer"}>
      <div className={"indicatorBall"} onClick={() => setSlide(0)}>
        {slide === 0 && (
          <motion.div
            className={"activeSlide"}
            layoutId={"activeSlideIndicator"}
          />
        )}
      </div>
      <div className={"indicatorBall"} onClick={() => setSlide(1)}>
        {slide === 1 && (
          <motion.div
            className={"activeSlide"}
            layoutId={"activeSlideIndicator"}
          />
        )}
      </div>
      <div className={"indicatorBall"} onClick={() => setSlide(2)}>
        {slide === 2 && (
          <motion.div
            className={"activeSlide"}
            layoutId={"activeSlideIndicator"}
          />
        )}
      </div>
      <div className={"indicatorBall"} onClick={() => setSlide(3)}>
        {slide === 3 && (
          <motion.div
            className={"activeSlide"}
            layoutId={"activeSlideIndicator"}
          />
        )}
      </div>
    </div>
  );
};
