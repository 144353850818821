import "./style.css";
import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import plane from "../../assets/plane.png";
import { ScrollToTopOnMount } from "../../utils/scrollToTopOnMount";

export const NotFound = () => {
  return (
    <div className={"notFoundPage snap"}>
      <ScrollToTopOnMount />
      <motion.h1 whileHover={{ scale: 1.1 }}>404</motion.h1>
      <h2>You landed on non-existing page.</h2>
      <Link to={"/"} aria-label={"Home page"}>
        <p>Return to homepage</p>
      </Link>
      <motion.div
        className={"planeNotFound"}
        whileHover={{ scale: 1.2 }}
        initial={{
          opacity: 0,
          x: "300px",
          y: "-200px",
          scale: 0.5,
          transition: {
            duration: 1,
          },
        }}
        whileInView={{
          opacity: 1,
          scale: 1.1,
          x: "0px",
          y: "0px",
          transition: {
            duration: 1,
          },
        }}
      >
        <img src={plane} alt={"plane"} alt={"Landed on non-existing page"} />
      </motion.div>
    </div>
  );
};
