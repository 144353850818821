import React, { useEffect, useRef, useState } from "react";
import {
  useIndicatorUpdate,
  useIndicatorValue,
} from "../../utils/slideIndicator";
import { useInView } from "react-intersection-observer";
import ClientTestimonials from "./ClientTestimonials";
import { motion } from "framer-motion";
import useMediaQuery from "../../utils/responsiveHook";
import { Link } from "react-router-dom";

export const ThirdSection = () => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const { ref, inView, entry } = useInView({
    threshold: isMobile ? 0.3 : 0.6,
    delay: 200,
    trackVisibility: true,
  });
  const setSlide = useIndicatorUpdate();
  const slide = useIndicatorValue();

  useEffect(() => {
    if (inView) {
      setSlide(2);
    }
  }, [inView]);

  useEffect(() => {
    if (slide === 2 && !inView) {
      entry.target.scrollIntoView({ behavior: "smooth" });
    }
  }, [slide]);

  return (
    <motion.div
      className={"thirdSection"}
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
      // viewport={{ once: true }}
    >
      <h1 className={"noselect"}>
        You make us. And we are thankful <br /> for that :)
      </h1>
      <div className={"thirdSecSplit"}>
        <div className={"textSection"}>
          <p>
            Without you, there is no Clix Productions. <br />
            In return for what you do for us, we will always go out of our way
            for you.
            <br />
            The size of your brand does not matter. The vision does.
            <Link to={"/#contact"}>
              <span className={"messageBold"}>Let's unfold it together.</span>
            </Link>
          </p>
          <p>
            We have worked (and will continue to work) with content creation for
            recognized brands from A to Z. <br />
            We talked to a couple of them.
            <span className={"messageBold"}>This is what they said.</span>
          </p>
        </div>
        <ClientTestimonials />
      </div>
    </motion.div>
  );
};
