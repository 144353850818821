import "./style.css";
import React from "react";
import { ScrollToTopOnMount } from "../../utils/scrollToTopOnMount";
import planeGif from "./../../assets/planeAroundPlanet.gif";
import { motion } from "framer-motion";
import { root } from "../../data/projectsList";
import { team } from "../../data/team";

const TeamMember = ({ member }) => {
  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };

  return (
    <motion.div className={"teamMemberWrapper"} variants={item}>
      <div className={"teamMemberOverlay"}>
        <h2>{member.name}</h2>
      </div>
      <img src={root + member.image} alt={member.name + "portrait"} />
      <div className={"teamMemberMeta"}>
        <h3>{member.title}</h3>
        <p>{member.email}</p>
      </div>
    </motion.div>
  );
};

export const About = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  return (
    <motion.div
      className={"aboutPage"}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
      viewport={{ once: true }}
    >
      <ScrollToTopOnMount />
      {/*<h1>CLIX PRODUCTIONS</h1>*/}
      <div className={"aboutContainer"}>
        <div className={"aboutDescription"}>
          <p>
            Clix Productions was founded in 2019 as a multi-functional creative
            production company specialized in visual storytelling through video,
            photo and graphic.
          </p>
          <p>
            Our mission is to is help you navigate through the vibrant world of
            content, leaving behind an undeniable impression of your brand’s
            identity. We aspire to do this together with you as we pay close
            attention to your brands values, believes and character. moreover,
            we aim to prioritize your satisfaction along all the steps of the
            creative process.
          </p>
          <p>
            We work closely together in a team to provide the most complete
            content on all levels of production.
          </p>

          <p>to help you unfold your visions.</p>
        </div>

        <div className={"aboutTeam"}>
          <img src={planeGif} />
        </div>
      </div>
      <motion.div
        className={"teamContainer"}
        variants={container}
        initial="hidden"
        animate="show"
      >
        {team.map((member) => (
          <TeamMember member={member} key={member.name} />
        ))}
      </motion.div>
    </motion.div>
  );
};
