import React, { useEffect, useRef } from "react";
import { BsCaretDownFill } from "@react-icons/all-files/bs/BsCaretDownFill";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import {
  useIndicatorUpdate,
  useIndicatorValue,
} from "../../utils/slideIndicator";
import useMediaQuery from "../../utils/responsiveHook";

export const FirstSection = () => {
  const { ref, inView, entry } = useInView({
    threshold: 0.6,
    delay: 200,
    initialInView: true,
    trackVisibility: true,
  });

  const slide = useIndicatorValue();
  const setSlide = useIndicatorUpdate();
  const isMobile = useMediaQuery("(max-width: 900px)");

  useEffect(() => {
    if (inView) {
      setSlide(0);
    }
  }, [inView]);

  useEffect(() => {
    if (slide === 0 && !inView) {
      entry.target.scrollIntoView({ behavior: "smooth" });
    }
  }, [slide]);

  return (
    <div className={"firstSection"} ref={ref}>
      <div className={"backgroundVideo"} key={isMobile ? "mobile" : "desktop"}>
        {isMobile ? (
          <video
            autoPlay
            muted
            loop
            playsInline
            poster={"https://d185iu4tbca78w.cloudfront.net/mobilebanner-ff.png"}
          >
            <source
              src={"https://d185iu4tbca78w.cloudfront.net/mobilebanner.mp4"}
              type="video/mp4"
            />
          </video>
        ) : (
          <video
            autoPlay
            muted
            loop
            playsInline
            poster={"https://d185iu4tbca78w.cloudfront.net/banner-ff.png"}
          >
            <source
              src={"https://d185iu4tbca78w.cloudfront.net/banner.mp4"}
              type="video/mp4"
            />
          </video>
        )}
      </div>
      <div className={"firstPageMessage"}>
        <p>
          creative content production
          <br />
          by
          <span className={"messageBold"}>
            unfolding your visions through motion
          </span>
          .
        </p>
      </div>
      <div className={"quote"}>
        <Link to={"/#contact"} as="a" aria-label={"Contact us"}>
          <h1>UNFOLD VISION(S).</h1>
        </Link>
      </div>
      <div className={"videoArrowDown"} animate={{}}>
        <BsCaretDownFill />
      </div>
    </div>
  );
};
