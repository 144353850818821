import React from "react";
import "./style.css";
import { projects } from "../../data/projectsList";
import { motion } from "framer-motion";
import ProjectPreview from "./ProjectPreview";

export const ProjectGrid = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
      },
    },
  };

  return (
    <motion.div
      className={"projectsContainer"}
      variants={container}
      initial="hidden"
      animate="show"
    >
      {projects.map((project) => (
        <ProjectPreview project={project} key={project.name} />
      ))}
    </motion.div>
  );
};
