import React, { useContext, useState } from "react";

const IndicatorContext = React.createContext();
const IndicatorUpdateContext = React.createContext();

export function useIndicatorValue() {
  return useContext(IndicatorContext);
}

export function useIndicatorUpdate() {
  return useContext(IndicatorUpdateContext);
}

export const IndicatorProvider = ({ children }: any) => {
  const [slide, setSlide] = useState(0);

  return (
    <IndicatorContext.Provider value={slide}>
      <IndicatorUpdateContext.Provider value={setSlide}>
        {children}
      </IndicatorUpdateContext.Provider>
    </IndicatorContext.Provider>
  );
};
