import React, { useState } from "react";
import { root } from "../../data/projectsList";
import { getLogos } from "../../data/clients";
import { BsArrowRightShort } from "@react-icons/all-files/bs/BsArrowRightShort";
import { BsArrowLeftShort } from "@react-icons/all-files/bs/BsArrowLeftShort";
import { motion } from "framer-motion";
import useMediaQuery from "../../utils/responsiveHook";

function ClientLogoWrapper({}) {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const isTablet = useMediaQuery("(max-width: 1300px)");
  const [currentSet, setCurrentSet] = useState(0);
  const src = root + "clientLogosOnFrontpage/";

  var amountOfLogos;
  if (isMobile) {
    amountOfLogos = 3;
  } else if (isTablet) {
    amountOfLogos = 4;
  } else {
    amountOfLogos = 6;
  }

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };

  const container = {
    hidden: {},
    show: {
      transition: {
        delay: 1,
        staggerChildren: 0.15,
      },
    },
  };

  return (
    <motion.div
      key={currentSet}
      className={"logosContainer"}
      variants={container}
      initial="hidden"
      animate="show"
    >
      {getLogos(currentSet, amountOfLogos).map((logoSrc) => (
        <motion.div
          className={"clientLogoWrapper"}
          variants={item}
          key={logoSrc}
        >
          <img
            src={src + logoSrc}
            alt={"Client logo - " + logoSrc.slice(0, -4)}
          />
        </motion.div>
      ))}
      <div className={"clientLogosControls"}>
        <div
          className={"changeSetOfClients"}
          onClick={() => setCurrentSet((current) => (current - 1 + 6) % 6)}
        >
          <BsArrowLeftShort />
        </div>
        <div
          className={"changeSetOfClients"}
          onClick={() => setCurrentSet((current) => (current + 1) % 6)}
        >
          <BsArrowRightShort />
        </div>
      </div>
    </motion.div>
  );
}

export default ClientLogoWrapper;
