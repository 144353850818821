import React, { useState } from "react";
import { clientReviews } from "../../data/clients";
import { AiFillLinkedin } from "@react-icons/all-files/ai/AiFillLinkedin";
import { BsArrowRightShort } from "@react-icons/all-files/bs/BsArrowRightShort";
import { BsArrowLeftShort } from "@react-icons/all-files/bs/BsArrowLeftShort";
import { motion } from "framer-motion";

const ClientTestimonials = () => {
  const [currTestimonial, setTestimonial] = useState(0);

  const animation = {
    hidden: {
      opacity: 0,
      transition: {
        duration: 1,
      },
    },
    show: {
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <div className={"clientTestimonials"}>
      <motion.div
        className={"testimonialWrapper"}
        key={clientReviews[currTestimonial].name}
        variants={animation}
        initial={"hidden"}
        animate={"show"}
      >
        <p>"{clientReviews[currTestimonial].review}"</p>
        <div className={"testimonialAuthor"}>
          <h3>{clientReviews[currTestimonial].name}</h3>
          <a
            href={clientReviews[currTestimonial].profileLink}
            aria-label={"LinkedIn profile of client"}
          >
            <AiFillLinkedin />
          </a>
        </div>
        <div className={"testimonialLogo"}>
          <img
            src={clientReviews[currTestimonial].logo}
            alt={clientReviews[currTestimonial].name + "testimonial logo"}
          />
        </div>
      </motion.div>
      <div className={"testimonialControlWrapper"}>
        <div
          className={"testimonialControl"}
          onClick={() =>
            setTestimonial(
              (curr) => (curr - 1 + clientReviews.length) % clientReviews.length
            )
          }
        >
          <BsArrowLeftShort />
        </div>
        <div
          className={"testimonialControl"}
          onClick={() =>
            setTestimonial((curr) => (curr + 1) % clientReviews.length)
          }
        >
          <BsArrowRightShort />
        </div>
      </div>
    </div>
  );
};

export default ClientTestimonials;
