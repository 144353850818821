import React, { useState } from "react";
import { motion } from "framer-motion";
import ClientLogoWrapper from "./ClientLogoWrapper";

const ClientSection = () => {
  return (
    <motion.div
      className={"clientsContainer"}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
      // viewport={{ once: true }}
    >
      <h1>Clients</h1>
      <ClientLogoWrapper />
    </motion.div>
  );
};

export default ClientSection;
