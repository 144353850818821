import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import {
  useIndicatorUpdate,
  useIndicatorValue,
} from "../../utils/slideIndicator";
import { motion } from "framer-motion";
import ServiceBox from "./ServiceBox";
import useMediaQuery from "../../utils/responsiveHook";
import { BsArrowRightShort } from "@react-icons/all-files/bs/BsArrowRightShort";
import { Link } from "react-router-dom";
import { services } from "../../data/services";

export const SecondSection = () => {
  const isMobile = useMediaQuery("(max-width: 900px)");
  const { ref, inView, entry } = useInView({
    threshold: 0.1,
    delay: 200,
    trackVisibility: true,
  });
  const setSlide = useIndicatorUpdate();
  const slide = useIndicatorValue();

  useEffect(() => {
    if (inView) {
      setSlide(1);
    }
  }, [inView]);

  useEffect(() => {
    if (slide === 1 && !inView) {
      entry.target.scrollIntoView({ behavior: "smooth" });
    }
  }, [slide]);

  const container = {
    hidden: {},
    show: {
      transition: {
        delay: 1,
        staggerChildren: 0.15,
      },
    },
  };

  return (
    <motion.div
      className={"secondSection"}
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
      // viewport={{ once: true }}
    >
      <div className={"secondHeadline"}>
        <h1>You are here for a reason ...</h1>
        <h1>... and so are we.</h1>
      </div>
      <div className={"secondText"}>
        <p className={"messageBold"}>
          You are here with ideas, and we are here to make d*mn sure they come
          to life.
        </p>
        <p>
          <span className={"messageBold"}>Clix</span> is a Copenhagen-based
          production company, specialized in video/photo in wide
          variety of industries.
          <br />
          <span className={"messageBold"}>
            We make content that makes impact. To make your audience remember
            you. To make your brand timeless.
          </span>
        </p>
      </div>
      <motion.div className={"seeProjectsButtonWrapper"}>
        <Link to={"/projects"} as="a" aria-label={"Projects"}>
          <h2>See projects</h2>
        </Link>
        <BsArrowRightShort />
      </motion.div>
      <div className={"serviceWrapper"}>
        {(inView || isMobile) && (
          <motion.div
            className={"serviceBoxesContainer"}
            variants={container}
            initial="hidden"
            animate="show"
          >
            {services.map((service) => (
              <ServiceBox
                key={service.title}
                icon={service.icon}
                desc={service.desc}
                title={service.title}
              />
            ))}
          </motion.div>
        )}
      </div>
    </motion.div>
  );
};
