import React from "react";
import "./style.css";
import { FirstSection } from "./FirstSection";
import { SecondSection } from "./SecondSection";
import { ThirdSection } from "./ThirdSection";

import { IndicatorProvider } from "../../utils/slideIndicator";
import { Indicator } from "../../components/Indicator";
import { ScrollToTopOnMount } from "../../utils/scrollToTopOnMount";
import { ContactSection } from "./ContactSection";
import ClientSection from "./ClientSection";

export const LandingPage = () => {
  return (
    <div className={"landingWrapper"}>
      <ScrollToTopOnMount />
      <IndicatorProvider>
        <Indicator />
        <FirstSection />
        <SecondSection />
        <ThirdSection />
        {/*<FourthSection />*/}
        <ClientSection />
        <ContactSection />
      </IndicatorProvider>
    </div>
  );
};
