import liveNationLogo from "./../assets/logos/livenation.png";
import ligaLogo from "./../assets/logos/liga.png";
import zizziLogo from "./../assets/logos/zizzi.png";
import luggageHeroLogo from "./../assets/logos/luggagehero.png";
import labelFifth from "./../assets/logos/labelfifth.png";

export const clientReviews = [
  {
    name: "Daniel Jensen, Marketing Manager at Live Nation Denmark",
    profileLink: "https://www.linkedin.com/in/daniel-emebo-jensen-b344041b7/",
    company: "LiveNation",
    logo: liveNationLogo,
    review:
      "Live Nation Denmark has worked with Clix Productions on several occasions, both big and smaller productions. It has been a superb experience every time. Clix Productions are experts in delivering unique content that stands out, especially in social media feeds, taking video production for our artists and shows to the next level. Extremely flexible and time efficient at a fair price for a product of such high quality. It is always a pleasure to work together with the team and you can feel the devotion to their craft. They are very hands on and willing to take that extra step to deliver the perfect product. Huge recommendation!",
  },
  {
    name: "Rebecca Santos-Bay, Head of Global SoMe & Influencer Marketing",
    profileLink: "https://www.linkedin.com/in/rebecca-santos-bay/",
    company: "Zizzi",
    logo: zizziLogo,
    review:
      "It has been an great experience to collaborate with Clix Productions. Their delivery is always prompt and they are highly adaptable to any changes that may arise. In addition, Rik's professionalism is undeniable and he has a natural talent for bringing positive energy to any set. He is always willing to go the extra mile to ensure that the end product exceeds client's expectations. I highly recommend Clix Productions for their reliability, flexibility and their professional approach to delivering high-quality video content.",
  },
  {
    name: "Ihan Haydar, Danish drummer AT LIGA, tv personality and author",
    profileLink: "https://www.instagram.com/ihanhaydar/?hl=en",
    company: "Ihan Haydar",
    logo: ligaLogo,
    review:
      "I have been working with Rik for quite some time now and he’s my favorite videographer of ALL time. With over a decade of experience in the music and television industry, I have had the opportunity to work with many talented individuals, however, none have come close to matching Rik's level of skill and expertise.Crazy talented and just absolutely AWESOME to work with!",
  },
  {
    name: "Jannik Lawaetz, startup founder & crowdfunding expert, Luggage hero",
    profileLink: "https://www.linkedin.com/in/dklawaetz/",
    company: "Luggage Hero",
    logo: luggageHeroLogo,
    review:
      "Honestly, the guys at Clix are just the best. The best people to work with and definitely the best I have come across in our industry.",
  },
  {
    name: "Rasmus Holck Aarup, Director at Label & Fifth",
    profileLink:
      "https://www.linkedin.com/in/rasmus-holck-aarup-pedersen-9721abb6/",
    company: "Label & Fifth",
    logo: labelFifth,
    review:
      "We have had the privilege of working with Clix on  several projects, and the collaboration has  been nothing short of amazing. Skilled, creative, out-of-the-box-thinking and combining creativity with technical expertise is what you can expect every time.\n\n\n On behalf of our team, I would like to say that Clix Productions will always be our preferred partner when it comes to video and graphic materials. We look forward to working with them again in the future.\n",
  },
];

export const frontPageClients = [
  "formula.png",
  "bmw.png",
  "eurovision.png",
  "ironman.png",
  "redbull.png",
  "universal.png",
  "rekom.png",
  "faustix.png",
  "technogym.png",
  "bls.png",
  "boltens.png",
  "zeekr.png",
  "fcsunshine.png",
  "ashbiah.png",
  "clublasanta.png",
  "cphdeep.png",
  "cphharbourparade.png",
  "culturebox.png",
  "fabianmazur.png",
  "hoteljakob.png",
  "italobrothers.png",
  "labelfifth.png",
  "livenation.png",
  "mkae.png",
  "movingtalent.png",
  "museo.png",
  "musikilejet.png",
  "nieuwenor.png",
  "overstehof.png",
  "proudmary.png",
  "solar.png",
  "studio20.png",
  "trueenergy.png",
  "vernon.png",
  "warner.png",
  "winselerhof.png",
  "zizzi.png",
];

export function getLogos(index, count) {
  var modIndex = index % Math.ceil(frontPageClients.length / count);
  if (modIndex === Math.ceil(frontPageClients.length / count) - 1) {
    return frontPageClients.slice(modIndex * count);
  }
  return frontPageClients.slice(modIndex * count, (modIndex + 1) * count);
}
