import "./style.css";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { ProjectGrid } from "../../components/ProjectGrid";
import VideoJS from "../../components/VideoJS/VideoJS";
import { root } from "../../data/projectsList";
import { ScrollToTopOnMount } from "../../utils/scrollToTopOnMount";
import { motion } from "framer-motion";

export const Projects = () => {
  const { state } = useLocation();

  useEffect(() => {
    if (state === undefined || state === null) {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [state]);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    poster: root + "showreel/ff.png",
    sources: [
      {
        src: root + "showreel/showreeel.mp4",
        type: "video/mp4",
      },
    ],
  };

  return (
    <div className={"projectsPage"}>
      <ScrollToTopOnMount />
      <motion.div
        className={"projectsCover"}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { duration: 1 } }}
        viewport={{ once: true }}
      >
        <div className={"aboutProjects"}>
          <p>Clix productions kicked off in 2019.</p>
          <p>
            Since then we have gathered a rich portfolio of content for our
            clients and have been constantly updating our skills to make each
            and every project a success.
          </p>
          <p>
            A collection of shots that should not be left unseen are gathered in
            our showreel. Do us and yourself a favor and have a look :)
          </p>
          <p>scroll down for more projects and cases.</p>
        </div>
        <div className={"videoCoverProjects"}>
          <h1>Showreel</h1>
          <VideoJS options={videoJsOptions} />
        </div>
      </motion.div>
      <h1>PROJECTS</h1>
      <ProjectGrid />
    </div>
  );
};
