import React from "react";
import "./style.css";
import { motion } from "framer-motion";

export const Footer = () => {
  return (
    <motion.footer
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1, transition: { duration: 1 } }}
      // viewport={{ once: true }}
    >
      <h1>'Unfolding visions through motion'</h1>
      <h2>CLIX PRODUCTIONS</h2>
      <p className={"copyright"}>
        &copy; Copyright {new Date().getFullYear()} Clix Productions, CVR:
        40963391
      </p>
    </motion.footer>
  );
};
