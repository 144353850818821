import React, { useEffect, useRef, useState } from "react";
import planeImg from "./../../assets/planeAroundPlanet.png";
import planeGif from "./../../assets/planeAroundPlanet.gif";
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import { FiMail } from "@react-icons/all-files/fi/FiMail";

import {
  useIndicatorUpdate,
  useIndicatorValue,
} from "../../utils/slideIndicator";
import { useInView } from "react-intersection-observer";
import ContactForm from "../../components/ContactForm/ContactForm";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";

export const ContactSection = () => {
  // Check if in view, update indicator
  const { ref, inView, entry } = useInView({
    threshold: 0.6,
    delay: 200,
    trackVisibility: true,
  });

  const setSlide = useIndicatorUpdate();
  const slide = useIndicatorValue();

  useEffect(() => {
    if (inView) {
      setSlide(3);
    }
  }, [inView]);

  useEffect(() => {
    if (slide === 3 && !inView) {
      entry.target.scrollIntoView({ behavior: "smooth" });
    }
  }, [slide]);

  const location = useLocation();
  const scrollTag = location.hash.substring(1);
  const contactRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (contactRef.current) {
      if (scrollTag === "contact") {
        contactRef.current.scrollIntoView({ behavior: "smooth" });
        navigate(location.pathname);
        setTimeout(() => {
          runAnimation();
        }, 300);
      }
    }
  }, [scrollTag]);

  //plane animation
  const [gif, setGif] = useState(false);

  function runAnimation() {
    setGif(true);
    setTimeout(() => {
      setGif(false);
    }, 1500);
  }

  return (
    <motion.div
      className={"contactSection"}
      ref={ref}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
      // viewport={{ once: true }}
    >
      <h1 className={"noselect"} ref={contactRef}>
        Let's get in touch!
      </h1>
      <div className={"contactSplit"}>
        <ContactForm runAnimation={() => runAnimation()} />
        <div className={"contactText"}>
          <p>
            <FaPhoneAlt />
            <a href={"tel:004528766105"}>+45 28 76 61 05</a>
          </p>
          <p>
            <FiMail />
            info@clixproductions.com
          </p>
          <p>
            We understand the feeling of putting your brands vision, identity
            and content in someone else's hands. It can be quite the leap. But
            rest assured that ours are capable, trustworthy, efficient and
            performance oriented. We guarantee quality and do not stop until we
            get a smile in return :)
          </p>
          <div className={"contactPlane"}>
            {gif ? (
              <img src={planeGif} alt={"plane"} />
            ) : (
              <img src={planeImg} alt={"plane"} />
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
