import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { root } from "../../data/projectsList";
import { motion } from "framer-motion";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";

export const ProjectPreview = (project) => {
  const [showDetails, setDetails] = useState(false);

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };

  const overlayAnimation = {
    opacity: showDetails ? 0 : 1,
    transition: {
      duration: 0.5,
    },
  };

  const detailsAnimation = {
    y: showDetails ? "0px" : "100px",
    transition: {
      duration: 0.5,
    },
  };

  const video = useRef(null);

  const stopMovie = () => {
    if (video) {
      if (!video.current.paused) {
        video.current.pause();
      }
    }
  };

  const playMovie = () => {
    if (video) {
      video.current
        .play()
        .then(() => {
          // do nothing
        })
        .catch(() => {
          stopMovie();
        });
    }
  };

  useEffect(() => {
    if (video.current) {
      const handleLoadedMetadata = () => {
        video.current.style.objectFit = "contain";
      };
      video.current.addEventListener("loadedmetadata", handleLoadedMetadata);

      return () => {
        if (video.current) {
          video.current.removeEventListener("loadedmetadata", () => {});
        }
      };
    }
  }, []);

  return (
    <motion.div
      variants={item}
      className={"projectPreview"}
      onMouseLeave={() => {
        setDetails(false);
        stopMovie();
      }}
      onMouseEnter={() => {
        setDetails(true);

        playMovie();
      }}
    >
      <motion.div className={"projectsOverlay"} animate={overlayAnimation}>
        {project.project.logo ? (
            <img
                src={root + project.project.directory + project.project.logo}
                alt={"Project logo"}
            />
        ) : (
            <h2>{project.project.name}</h2>
        )}
      </motion.div>
      <Link
        to={"/project/" + project.project.id}
        state={{ scrollFalse: true }}
        className={"projectsItem"}
        as="a"
        aria-label={"Project " + project.project.name}
      >
        <video
          preload={"metadata"}
          muted
          loop
          playsInline
          webkit-playsinline={"true"}
          ref={video}
          poster={
            root + project.project.directory + "small-" + project.project.poster
          }
        >
          <source
            src={
              root +
              project.project.directory +
              "small-" +
              project.project.video
            }
            type="video/mp4"
          />
        </video>
        <motion.div className={"viewMoreOverlay"} animate={detailsAnimation}>
          <h3>{project.project.name}</h3>
          <FaArrowRight />
        </motion.div>
      </Link>
    </motion.div>
  );
};

export default ProjectPreview;
